import React from "react"

import Layout from "../../components/Layout"

const AdminLoginPage = () => (
  <Layout>
    <h1>AdminLogin Page</h1>
  </Layout>
)

export default AdminLoginPage
